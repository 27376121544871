import styled, { keyframes } from "styled-components";
import { Color } from "../../layout/GlobalStyles/Color";
import alert from "./alert.svg";
import { AlertType } from "./AlertsContext";
import success from "./success.svg";

export const Root = styled.div`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    backdrop-filter: blur(1px) saturate(180%);
    -webkit-backdrop-filter: blur(1px) saturate(180%);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
    background-color: ${Color.Black10};
`;

interface IStatusIconProps {
    readonly type: AlertType;
}

export const StatusIcon = styled.div<IStatusIconProps>`
    display: inline-block;
    margin-top: 8px;
    width: 60px;
    height: 60px;
    background-image: url(${({ type: icon }) => (icon === AlertType.Success ? success : alert)});
`;

export const Description = styled.p`
    padding: 8px;
`;

export const AlertCard = styled.div`
    cursor: default;
    position: relative;
    background: #ffffff;
    display: inline-block;
    margin: 0px auto;
    text-align: center;
    min-width: 300px;
    padding: 16px;
    opacity: 1;
    border-radius: 4px;
    padding-bottom: 32px;
`;

const progressAnimation = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
`;
interface IProgressBarProps {
    readonly duration: number;
}

export const ProgressBar = styled.div<IProgressBarProps>`
    animation: ${progressAnimation} ${({ duration }) => duration}s linear;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    background: ${Color.Primary50};
    height: 16px;
    width: 0%;
    border-radius: 4px;
`;
